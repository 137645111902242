import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { splittingContent } from 'components/print/InsSplittingContent'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { isHeadline } from 'helpers/detail.helper'
import { dateToStr } from 'helpers/date.helper'
import { TruckIcon } from 'components/InsIcon'
import { formatNumber } from 'helpers/number.helper'
import { renderInstructionExtendRow } from 'modules/business/instruction/InstructionHelper'
import InstructionTypeEnum from 'enums/instruction_type.enum'
import InsNoteAffix from 'components/InsNoteAffix'
import InsTextArea from 'components/InsTextArea'
import { isEmpty } from 'helpers/string.helper'

const InstructionDOMPrint = ({ project, instruction, componentRef, hideColumns }) => {
    const { t } = useTranslation()

    let refs = {}
    let refIndex = 1
    let nextRef = {}

    const setRefs = (el, isTHead = false, withoutTHead = false, similarTHead = false, splitByCls = false) => {
        refs[refIndex] = {
            elem: el,
            isTHead: isTHead,
            withoutTHead: withoutTHead,
            similarTHead: similarTHead, // truck, or project row
            splitByCls: splitByCls,
        }
        refIndex++
    }

    const setNextRef = el => {
        nextRef = {
            elem: el,
        }
    }

    let nextRefInstruction = {}
    let nextRefInstructionIndex = 1
    const setNextRefInstruction = el => {
        nextRefInstruction[nextRefInstructionIndex] = {
            elem: el,
        }
        nextRefInstructionIndex++
    }

    // ref
    const renderHeadFirstPage = productClassification => {
        return <div ref={el => setRefs(el)}>{renderHeader(productClassification)}</div>
    }

    // ref
    const renderNoteGroup = () => {
        return (
            <div ref={el => setRefs(el, false, true)} className="p-note-group">
                <label className="p-bold">{'備考'}</label>
                <div className="p-note-group-textarea">{instruction.note}</div>
            </div>
        )
    }

    // ref: repeatable
    const renderHeadNextPage = productClassification => {
        return <div ref={el => setNextRef(el)}>{renderHeader(productClassification, 99)}</div>
    }

    // ref: multi
    const renderHeadNextPageInstruction = () => {
        let group = []
        instruction.details.map(d => {
            if (
                !isHeadline(d) &&
                d.processed_product_classification &&
                group.indexOf(d.processed_product_classification) < 0
            ) {
                group.push(d.processed_product_classification)
            }
            return null
        })
        return group.map((g, index) => (
            <Fragment key={index}>
                <div ref={el => setNextRefInstruction(el)}>{renderHeader(g, 99)}</div>
            </Fragment>
        ))
    }

    const renderTags = instruction => {
        const tags = [
            {
                text: t('column:Is included'),
                isTrue: instruction.is_included,
            },
            {
                text: t('column:Is hold sales'),
                isTrue: instruction.is_hold_sales,
            },
            {
                text: t('column:Specified sales date'),
                isTrue: instruction.is_specify_sales_date,
            },
            {
                text: t('column:Serial number sales'),
                isTrue: instruction.is_serial_number_sales,
            },
            {
                text: t('column:Is additional included'),
                isTrue: instruction.is_additional_included,
            },
            {
                text: t('column:Is replacement'),
                isTrue: instruction.is_replacement,
            },
            {
                text: t('column:Is time service'),
                isTrue: instruction.is_time_service,
            },
            {
                text: t('column:Invoice no notice'),
                isTrue: instruction.is_invoice_no_notice,
            },
        ]
        return (
            <>
                {tags.map(
                    (t, index) =>
                        !!t.isTrue && (
                            <div className="i-tag-wrap" key={`tag_${index}`}>
                                <div className="i-tag-text">{t.text}</div>
                            </div>
                        )
                )}
            </>
        )
    }

    const renderHeader = (productClassification, pageNum = 1) => {
        let pageNumString = pageNum < 10 ? '0' + pageNum : pageNum
        return (
            <div style={{ minHeight: 75 }}>
                <div className="p-right-page-num-wrap">
                    <div className="p-right-page-num">
                        <div className="p-page-num">
                            {'PAGE - '}
                            {pageNumString}
                        </div>
                        <table className="p-right-table">
                            <tbody>
                                <tr>
                                    <th>
                                        {t('column:Project code')}
                                        <span>{':'}</span>
                                    </th>
                                    <td>
                                        <span className="p-bold">{project.project_code}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t('column:Instruction code')}
                                        <span>{':'}</span>
                                    </th>
                                    <td>{instruction.instruction_code}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {renderPageTitleWrap(productClassification)}
            </div>
        )
    }

    const getTitleManual = productClassification => {
        return (
            getClassificationItemName(
                instruction.instruction_classification === 1
                    ? ClassificationTypeEnum.IN_HOUSE_PROCESSED.value
                    : ClassificationTypeEnum.OUTSIDE_PROCESSED.value,
                productClassification
            ) || ''
        )
    }

    const renderPageTitleWrap = productClassification => {
        return (
            <div className="i-page-title-wrap">
                <div className="i-page-title-text">{`${getTitleManual(productClassification)}${
                    instruction.instruction_classification === 1 ? '加工' : ''
                }指示書`}</div>
                <div className="i-tags-wrap">{renderTags(instruction)}</div>
            </div>
        )
    }

    const renderCustomer = instruction => {
        const customer = instruction.customer
        if (!customer) return false
        return (
            <>
                <span className="p-bold mr-16">{customer.customer_name}</span>
                <span className="p-bold mr-16">{'ご担当者'}</span>
                <span className="p-bold mr-16">
                    {getClassificationItemName(
                        ClassificationTypeEnum.CUSTOMER_TITLE.value,
                        instruction.customer_pic_title
                    )}
                </span>
                {customer.phone_number && <span>{`TEL：${customer.phone_number}`}</span>}
            </>
        )
    }
    const renderShipper = instruction => {
        const shipper = instruction.shipper
        if (!shipper) return false
        return (
            <>
                <span className="p-bold">
                    {shipper.shipper_name}
                    {'　半田　様'}
                </span>
                <br />
                <span>
                    {'〒'}
                    {shipper.post_code}
                    {'　'}
                    {getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, shipper.prefecture_code)}
                    {shipper.address1} {shipper.address2 || ''}
                </span>
                <br />
                {shipper.phone_number && <span>{`TEL：${shipper.phone_number}`}</span>}
            </>
        )
    }

    const isOutsource = instruction.instruction_classification === InstructionTypeEnum.OUTSOURCE.value
    const renderPageTable1 = isHide => {
        return (
            <>
                {!isHide && (
                    <div ref={el => setRefs(el)} className="i-page-table-1">
                        <table className="p-table">
                            <tbody>
                                <tr className="p-border p-border-top-dark">
                                    <th className="p-h-48 p-bg-50 p-border-left-0">{t('column:Customer name')}</th>
                                    <td colSpan={5}>{renderCustomer(instruction)}</td>
                                </tr>
                                <tr className="p-border">
                                    <th className="p-w-114 p-bg-50 p-border-left-0">{t('column:Arrival date')}</th>
                                    <td className="p-w-169 p-bold">
                                        <span>
                                            {dateToStr(instruction.arrival_date, 'M月 DD日')}
                                            {'　'}
                                        </span>
                                        <span>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                instruction.arrival_time
                                            )}
                                        </span>
                                    </td>
                                    {isOutsource ? (
                                        <>
                                            <th className="p-w-114 p-bg-50">{t('受注日')}</th>
                                            <td className="p-w-179 p-nowrap">
                                                <span className="p-bold mr-16">
                                                    {dateToStr(instruction.received_order_date)}
                                                </span>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <th className="p-w-114 p-bg-50">{t('column:Delivery type')}</th>
                                            <td className="p-w-179 p-nowrap">
                                                <span className="p-bold mr-16">
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.DELIVERY_CATEGORY_1.value,
                                                        instruction.delivery_type
                                                    )}
                                                </span>
                                                {instruction.delivery_type === '1' && instruction.transport_type && (
                                                    <span>{`(${getClassificationItemName(
                                                        ClassificationTypeEnum.DELIVERY_CATEGORY_2.value,
                                                        instruction.transport_type
                                                    )}宅急便)`}</span>
                                                )}
                                            </td>
                                        </>
                                    )}
                                    <th className="p-w-134 p-bg-50">{t('Inasa representative')}</th>
                                    <td className="p-w-198">{instruction.pic && instruction.pic.pic_name}</td>
                                </tr>
                                {isOutsource ? (
                                    <>
                                        <tr className="p-border">
                                            <th className="p-w-114 p-bg-50 p-border-left-0">{t('出荷日')}</th>
                                            <td className="p-w-169 p-bold">
                                                <span>
                                                    {dateToStr(instruction.shipping_date, 'M月 DD日')}
                                                    {'　'}
                                                </span>
                                                <span>
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                        instruction.shipping_time
                                                    )}
                                                </span>
                                            </td>
                                            <th className="p-w-114 p-bg-50">{t('発注書番号')}</th>
                                            <td className="p-w-179">
                                                <span className="p-bold mr-16 p-nowrap">
                                                    {instruction.place_order_code}
                                                </span>
                                            </td>
                                            <th className="p-w-134 p-bg-50">{t('発注タイミング')}</th>
                                            <td className="p-w-198">
                                                <span className="p-bold p-nowrap">
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.OUTSOURCE_TIMING.value,
                                                        instruction.outsource_timing
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr className="p-border">
                                            <th className="p-w-114 p-bg-50 p-border-left-0">{t('納品日')}</th>
                                            <td className="p-w-169 p-bold">
                                                <span>
                                                    {dateToStr(instruction.delivery_date, 'M月 DD日')}
                                                    {'　'}
                                                </span>
                                                <span>
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                        instruction.delivery_time
                                                    )}
                                                </span>
                                            </td>
                                            <th className="p-w-114 p-bg-50">{t('発注先')}</th>
                                            <td className="p-w-179" colSpan={3}>
                                                <span className="p-bold mr-16 p-nowrap">
                                                    {instruction.outsource?.outsource_name}
                                                </span>
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr className="p-border">
                                        <th className="p-w-114 p-bg-50 p-border-left-0">
                                            {t('column:Processing arrival date')}
                                        </th>
                                        <td className="p-w-169 p-bold">
                                            <span>
                                                {dateToStr(instruction.processing_arrival_date, 'M月 DD日')}
                                                {'　'}
                                            </span>
                                            <span>
                                                {getClassificationItemName(
                                                    ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                    instruction.processing_arrival_time
                                                )}
                                            </span>
                                        </td>
                                        <th className="p-w-114 p-bg-50">{t('column:Delivery date')}</th>
                                        <td className="p-w-179">
                                            <span className="p-bold mr-16 p-nowrap">
                                                {dateToStr(instruction.delivery_date, 'M月 DD日')}
                                                {'　'}
                                                {instruction.delivery_type === '2'
                                                    ? '←持ち出し'
                                                    : instruction.delivery_type === '3'
                                                    ? 'ご来社'
                                                    : '出荷'}
                                            </span>
                                        </td>
                                        <th className="p-w-134 p-bg-50">{t('column:Scheduled arrival date')}</th>
                                        <td className="p-w-198">
                                            <span className="p-bold p-nowrap">
                                                {dateToStr(instruction.scheduled_arrival_date, 'M月 DD日')}
                                                {'　'}
                                            </span>
                                            <span className="p-bold p-nowrap">
                                                {getClassificationItemName(
                                                    ClassificationTypeEnum.INSTRUCTION_ARRIVAL_SCHEDULED.value,
                                                    instruction.scheduled_arrival_time
                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                <tr className="p-border">
                                    <th className="p-w-114 p-bg-50 p-border-left-0">
                                        {t('column:Specified sales date')}
                                    </th>
                                    <td className="p-w-169 p-bold">
                                        <span>{dateToStr(instruction.specified_sales_date, 'M月 DD日')}</span>
                                    </td>
                                    <th className="p-w-114 p-bg-50">{t('column:Serial number sales')}</th>
                                    <td className="p-w-179" colSpan={isOutsource ? 3 : 1}>
                                        <span>{instruction.serial_number_sales}</span>
                                    </td>
                                    {!isOutsource && (
                                        <>
                                            <th className="p-w-134 p-bg-50">{'送り状お知らせ'}</th>
                                            <td className="p-w-198 p-nowrap">
                                                {getClassificationItemName(
                                                    ClassificationTypeEnum.INSTRUCTION_NO_INVOICE.value,
                                                    instruction.invoice_no_notice
                                                )}
                                            </td>
                                        </>
                                    )}
                                </tr>
                                <tr className="p-border p-border-bottom-dark">
                                    <th className="p-bg-50 p-border-left-0">{t('column:Shipper')}</th>
                                    <td colSpan={5} className="p-note">
                                        {renderShipper(instruction)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </>
        )
    }

    const renderDeliveryDestinations = (productClassification, destinations) => {
        return destinations
            .filter(des => des.details.find(d => d.processed_product_classification === productClassification))
            .map(des => (
                <>
                    <div ref={el => setRefs(el, false, true, true)}>
                        <div className="i-table-detail-shipping for-instruction pt-16 mb-0 pb-8">
                            <div className="i-table-shipping-type">
                                <span className="p-truck mr-8">
                                    <TruckIcon size={24} />
                                </span>
                                <span className="p-shipping-text">{`${t('column:Delivery destination')} :`}</span>
                            </div>
                            <div className="i-table-shipping-detail">
                                <div className="i-table-shipping-detail-line1">
                                    <span className="p-bold mr-16">{des.delivery_destination_name}</span>
                                    <span className="p-bold mr-16">{des.delivery_destination_pic_name}</span>
                                    <span className="p-bold mr-16">{des.delivery_destination_pic_title}</span>
                                </div>
                                <div className="i-table-shipping-detail-line2">
                                    <span className="mr-24">{des.delivery_destination_address}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {renderDetails(
                        productClassification,
                        des.details.filter(d => d.processed_product_classification === productClassification)
                    )}
                </>
            ))
    }

    // ref
    const renderTHead = () => {
        return (
            <div ref={el => setRefs(el, true)} className="dom-row-thead">
                <span style={{ flex: 220 }}>{t('common:Title')}</span>
                <span style={{ flex: 220 }}>{t('column:Spec')}</span>
                <span style={{ flex: 144 }}>{t('column:Size')}</span>
                <span style={{ flex: '0 0 92px' }}>{t('column:Quantity')}</span>
                <span style={{ flex: '0 0 48px' }}>{t('column:Unit')}</span>
                {!hideColumns.includes('unit_price') && (
                    <span style={{ flex: '0 0 92px' }}>{t('column:Unit price')}</span>
                )}
                {!hideColumns.includes('unit_cost') && <span style={{ flex: '0 0 92px' }}>{t('column:Cost')}</span>}
            </div>
        )
    }

    const renderDetails = (productClassification, details) => {
        return (
            <>
                {renderTHead()}
                {details
                    .filter(d => d.processed_product_classification === productClassification)
                    .map((d, index) => (
                        <Fragment key={d.index || index}>{renderDetail(d)}</Fragment>
                    ))}
            </>
        )
    }

    const renderDetail = d => {
        return (
            <div ref={el => setRefs(el)} className="dom-row-detail" style={{ flexWrap: 'wrap' }}>
                <span className="p-bold" style={{ flex: 220 }}>
                    <InsNoteAffix
                        note={
                            <InsTextArea
                                className="color-gray-678 textarea-auto-size text-area-preview"
                                autoSize={true}
                                disabled={true}
                                allowClear={false}
                                defaultValue={d.note}
                            />
                        }
                        showCorner={!isEmpty(d.note)}
                    >
                        <span>{d.title}</span>
                    </InsNoteAffix>
                </span>
                <span style={{ flex: 220 }}>{d.spec}</span>
                <span style={{ flex: 144 }}>{d.size}</span>

                <span style={{ flex: '0 0 92px' }}>{formatNumber(d.quantity)}</span>
                <span
                    style={{
                        flex: '0 0 48px',
                        borderRightWidth:
                            hideColumns.includes('unit_price') && hideColumns.includes('unit_cost') ? 0 : null,
                    }}
                >
                    {getClassificationItemName(ClassificationTypeEnum.PRODUCT_UNIT.value, d.unit)}
                </span>
                {!hideColumns.includes('unit_price') && (
                    <span style={{ flex: '0 0 92px', borderRightWidth: hideColumns.includes('unit_cost') ? 0 : null }}>
                        {formatNumber(d.unit_price)}
                    </span>
                )}
                {!hideColumns.includes('unit_cost') && (
                    <span style={{ flex: '0 0 92px', borderRightWidth: 0 }}>{formatNumber(d.unit_cost)}</span>
                )}

                {renderInstructionExtendRow(d.id, [d])}
            </div>
        )
    }

    const renderTableDetailsWrap = productClassification => {
        return (
            <>
                {instruction.delivery_destinations.length > 0
                    ? renderDeliveryDestinations(productClassification, instruction.delivery_destinations)
                    : renderDetails(productClassification, instruction.details)}
            </>
        )
    }

    const recursiveRender = () => {
        let group = []
        instruction.details.map(d => {
            if (
                !isHeadline(d) &&
                d.processed_product_classification &&
                group.indexOf(d.processed_product_classification) < 0
            ) {
                group.push(d.processed_product_classification)
            }
            return null
        })
        return group.map((type, index) => (
            <Fragment key={index}>
                {renderHeadFirstPage(type)}
                {renderPageTable1()}
                {renderTableDetailsWrap(type)}
                {renderNoteGroup()}
                <div ref={el => setRefs(el, false, true, false, true)} />
            </Fragment>
        ))
    }

    const renderPreview = () => {
        return (
            <>
                <div className="dom-preview-visibility">
                    <div className="DOM-single-page dom-page-style dom-mod-style-header prv-instruction">
                        {recursiveRender()}
                        {renderHeadNextPage()}
                        {renderHeadNextPageInstruction()}
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        setSplitContents(splittingContent(refs, nextRef, null, false, nextRefInstruction))
    }, [refs, nextRef])

    const renderPages = () => {
        return (
            <div style={{ display: 'none' }}>
                <div
                    ref={componentRef}
                    className="DOM-multi-pages dom-page-style dom-mod-style-header prv-instruction"
                    dangerouslySetInnerHTML={{ __html: splitContents }}
                />
            </div>
        )
    }

    const [splitContents, setSplitContents] = useState('')
    // render
    const render = () => (
        <>
            {renderPreview()}
            {renderPages()}
        </>
    )

    return render()
}

export default InstructionDOMPrint

InstructionDOMPrint.propTypes = {
    project: PropTypes.object.isRequired,
    instruction: PropTypes.object,
    componentRef: PropTypes.any,
    hideColumns: PropTypes.array,
}
