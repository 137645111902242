import React, { useRef } from 'react'
import { Row, Select } from 'antd'
import InsInputSearch from 'components/InsInputSearch'
import { useTranslation } from 'react-i18next'
import InsSelect from 'components/select/InsSelect'
import InsDateRangePicker from 'components/datepicker/InsDateRangePicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { setKeywords } from 'modules/business/shipment/shipment.slice'
import { DisplayShipmentTypeEnum, ShipmentType } from 'enums/shipment.enum'

const ShipmentListSearch = ({ state, onChangeDisplayShipmentType, updateFilters, activeKey }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const searchHandler = value => {
        const newFilters = Object.assign({}, state.filters, {
            'time:gte': value[0] + '\t00:00:00',
            'time:lte': value[1] + '\t23:59:59',
        })
        updateFilters(newFilters)
    }

    return (
        <Row gutter={[8, 16]} align="middle" style={{ backgroundColor: '#dae2e8', padding: 8 }}>
            <InsSelect
                value={state.filters['display_shipment_type']}
                onChange={value => {
                    onChangeDisplayShipmentType(value)
                }}
                style={{ width: '120px', marginRight: '4px' }}
            >
                {Object.values(DisplayShipmentTypeEnum).map(displayType => (
                    <Select.Option key={displayType.value} value={displayType.value}>
                        {displayType.text}
                    </Select.Option>
                ))}
            </InsSelect>

            <InsDateRangePicker
                className="mr-4"
                value={[moment(state.filters['time:gte']), moment(state.filters['time:lte'])]}
                onChange={(mm, value) => {
                    searchHandler(value)
                }}
            />
            <InsInputSearch
                style={{ width: '258px' }}
                placeholder={
                    activeKey === ShipmentType.OUTGOING.value
                        ? t('案件番号、顧客名、品名')
                        : t('案件番号、仕入先名、品名')
                }
                value={state.keywords}
                onChange={values => {
                    dispatch(setKeywords(values))
                }}
                onSearch={values => updateFilters({ keyword: values })}
            />
        </Row>
    )
}

const mapState = state => ({ state: state.shipment })
export default connect(mapState)(ShipmentListSearch)

ShipmentListSearch.propTypes = {
    onChangeDisplayShipmentType: PropTypes.func,
    getShipments: PropTypes.func,
    updateFilters: PropTypes.func,
    activeKey: PropTypes.string,
}
