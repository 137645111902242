import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import InsInput from 'components/InsInput'
import { getClassificationItemName } from 'helpers/classification.helper'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { PreviewIcon } from 'components/InsIcon'
import history from 'helpers/history.helper'
import Routes from 'routes'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import InsTextArea from 'components/InsTextArea'
import InsFloatBar from 'components/InsFloatBar'
import InsInputNumber from 'components/InsInputNumber'
import InsButton from 'components/InsButton'
import moment from 'moment'
import RedSlipProject from 'modules/business/redSlip/redSlipProject/RedSlipProject'
import InsModal from 'components/InsModal'
import RouterPrompt from 'modules/common/ui/RouterPrompt'
import RedSlipDOMPrint from 'modules/business/redSlip/RedSlipDOMPrint'

const RedSlipForm = ({ isEdit, project, sale, redSlip, response, initialValues = {}, onSubmit }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [modalRedSlipPreview, setModalRedSlipPreview] = useState(false)
    const [redSlipPreviewModal, setRedSlipPreviewModal] = useState({})
    const [isValueChange, setIsValueChange] = useState(!isEdit)
    const [modalConfirmSave, setModalConfirmSave] = useState(false)

    const customer = project.customer
    const suggestionList = customer.customer_pic ? customer.customer_pic.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    initialValues = Object.assign(
        {
            project_id: project.id,
            customer_id: customer.id,
            customer_pic_name: sale.customer_pic_name,
            customer_pic_title: sale.customer_pic_title,
            red_slip_code: project.project_code + '-WK-' + (project?.count_red_slip ?? '1'),
            red_slip_name: sale.sale_name,
            red_slip_issued_date: sale.delivery_date,
            pic: sale.pic,
            pic_id: sale.pic_id,
            sender_id: sale.sender_id,
            sender_address: sale.sender_address,
            sender_name: sale.sender_name,
            sale_id: sale.id,
            // red_slip_id: redSlip.id,
            gross_profit: sale.gross_profit,
            subtotal: sale.subtotal,
            consumption_tax: sale.consumption_tax,
            total: sale.total,
            note: sale.note,
        },
        initialValues
    )

    initialValues.project_id = project.id
    initialValues.red_slip_issued_date = initialValues.red_slip_issued_date
        ? moment.utc(initialValues.red_slip_issued_date, 'YYYY-MM-DD')
        : moment.utc(moment().format('YYYY-MM-DD'))

    const parseDataForSubmit = (values, isPreview = false) => {
        values.delivery_destinations = sale.delivery_destinations
        values.details = sale.details
        return values
    }

    const processOnSubmit = (values, redirectTo) => {
        if (isValueChange) {
            setIsValueChange(false)
        }
        onSubmit(values, redirectTo)
    }

    const dateFormat = 'YYYY年M月DD日'

    return (
        <div className="form-invoice bl-form">
            {modalConfirmSave && (
                <InsModal
                    title="確認"
                    visible={modalConfirmSave}
                    onCancel={() => setModalConfirmSave(false)}
                    onOk={() => processOnSubmit(form.getFieldsValue())}
                    okText="はい"
                    cancelText="いいえ"
                >
                    <p>保存しますか？</p>
                </InsModal>
            )}
            <RouterPrompt
                handleSave={path => {
                    let values = form.getFieldsValue()
                    return processOnSubmit(values, path)
                    // return true
                }}
                isValueChange={isValueChange}
            />
            <InsModal
                title={t('Red Slip Preview')}
                visible={modalRedSlipPreview}
                onCancel={() => setModalRedSlipPreview(false)}
                okText="確認"
                footer={null}
                className="preview-modal"
            >
                <RedSlipDOMPrint
                    project={project}
                    salePrint={sale}
                    redSlip={Object.assign(initialValues, redSlipPreviewModal)}
                    showPreview={true}
                    processPrint={false}
                />
            </InsModal>
            <Form
                form={form}
                initialValues={initialValues}
                onFinish={values => setModalConfirmSave(true)}
                onValuesChange={() => {
                    if (isEdit && !isValueChange) {
                        setIsValueChange(true)
                    }
                }}
            >
                <Row>
                    <Col>
                        <h1 className="page-title-big mt-24 mb-16">{'赤伝作成'}</h1>
                    </Col>
                </Row>

                <Form.Item name="project_id" style={{ display: 'none' }}>
                    <InsInput />
                </Form.Item>
                <Form.Item name="customer_id" style={{ display: 'none' }}>
                    <InsInput />
                </Form.Item>
                <Form.Item name="sale_id" style={{ display: 'none' }}>
                    <InsInput />
                </Form.Item>

                <Row className="mb-16">
                    <Col className="bl-form-col" span={12}>
                        <Form.Item label={t('column:RedSlip project code')}>
                            <InsInput
                                value={sale.sale_code}
                                disabled
                                style={{ width: 180 }}
                                className="customer-name-disabled"
                            />
                        </Form.Item>
                        <Form.Item label={t('column:Customer name')}>
                            <InsInput
                                value={sale.customer.customer_name}
                                disabled
                                style={{ width: 274 }}
                                className="customer-name-disabled"
                            />
                        </Form.Item>
                        <Form.Item label={t('column:Customer pic name')}>
                            <Form.Item name="customer_pic_name" noStyle>
                                <InsInput
                                    className="customer-name-disabled"
                                    value={sale.customer_pic_name}
                                    disabled
                                    options={result}
                                    onSearch={handleSuggestion}
                                    style={{ width: 180 }}
                                />
                            </Form.Item>
                            <Form.Item noStyle>
                                <InsInput
                                    className="customer-name-disabled"
                                    value={getClassificationItemName(
                                        ClassificationTypeEnum.CUSTOMER_TITLE.value,
                                        initialValues.customer_pic_title
                                    )}
                                    disabled
                                    options={result}
                                    onSearch={handleSuggestion}
                                    style={{ width: 90, marginLeft: ' 4px ' }}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item name="red_slip_name" className="customer-name-disabled" label={t('column:件名')}>
                            <InsInput style={{ width: 340 }} disabled />
                        </Form.Item>
                    </Col>
                    <Col className="bl-form-col" span={12}>
                        <Form.Item
                            name="red_slip_code"
                            label={t('column:RedSlip Code')}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter RedSlip code'),
                                    // check unique
                                },
                                {
                                    // trigger: 'onBlur',
                                    validator: async (rule, value) => {
                                        if (isEdit && initialValues.red_slip_code.trim() === value.trim())
                                            return Promise.resolve()

                                        if (value.trim()) {
                                            const data = await api.post(APP_URLS.RED_SLIP_CHECK_EXISTS_CODE, {
                                                red_slip_code: value,
                                            })
                                            if (data && data.is_existed) {
                                                return Promise.reject(t('validation:Red slip code existed'))
                                            }
                                        }

                                        return Promise.resolve()
                                    },
                                },
                                {
                                    max: 20,
                                    message: t('validation:{{item}} is invalid', {
                                        item: t('column:RedSlip Code'),
                                    }),
                                },
                            ]}
                            validateTrigger={['onBlur']}
                            className="hide-require"
                        >
                            <InsInput style={{ width: 180 }} />
                        </Form.Item>
                        <Form.Item
                            name="red_slip_issued_date"
                            label={t('column:RedSlip issued date')}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter issued date'),
                                },
                            ]}
                            className="hide-require"
                        >
                            <InsDatePicker
                                value={initialValues.red_slip_issued_date}
                                format={dateFormat}
                                placeholder="発行日入力"
                                style={{ width: 180 }}
                                allowClear={false}
                            />
                        </Form.Item>
                        <Form.Item label="差出名" className="hide-require">
                            <InsInput
                                className="customer-name-disabled"
                                style={{ width: 180 }}
                                value={getClassificationItemName(
                                    ClassificationTypeEnum.DELIVERY_NOTE_SENDER.value,
                                    initialValues.sender_id
                                )}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item name="sender_name" label=" ">
                            <InsInput
                                className="customer-name-disabled"
                                style={{ width: 180 }}
                                value={'株式会社TM'}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item name="sender_address" label=" " style={{ width: 420, marginBottom: 8 }}>
                            <InsTextArea
                                defaultValue={initialValues.sender_address}
                                disabled
                                allowClear={false}
                                style={{ backgroundColor: '#ECEFF1' }}
                            />
                        </Form.Item>
                        <Form.Item label={t('column:Pic name')} className="customer-name-disabled">
                            <InsInput disabled value={initialValues?.pic?.pic_name} style={{ width: 180 }} />
                        </Form.Item>
                    </Col>
                </Row>

                <RedSlipProject sale={sale} />

                <div>
                    <Row className="bl-form-redslip-detail-control" justify="space-between">
                        <Row className="total">
                            <Form.Item name="gross_profit" label="粗利">
                                <InsInputNumber disabled style={{ width: 104 }} withTriangle={true} />
                            </Form.Item>
                            <Form.Item name="subtotal" label="小計">
                                <InsInputNumber min={0} disabled style={{ width: 104 }} withTriangle={true} />
                            </Form.Item>
                            <Form.Item name="consumption_tax" label="消費税">
                                <InsInputNumber min={0} disabled style={{ width: 104 }} withTriangle={true} />
                            </Form.Item>
                            <Form.Item name="total" label="合計	：:">
                                <InsInputNumber min={0} disabled style={{ width: 104 }} withTriangle={true} />
                            </Form.Item>
                        </Row>
                    </Row>
                </div>
                <h3>備考</h3>
                <Form.Item name="note">
                    <InsTextArea value={initialValues.note} allowClear={false} disabled />
                </Form.Item>
                <InsFloatBar visible={!modalRedSlipPreview} className="with-sidebar z-9999">
                    <Row gutter={8} justify="space-between" align="middle">
                        <Col>
                            <InsButton
                                icon={<PreviewIcon size={16} className="color-pantone-2738-c" />}
                                buttonStyle="default"
                                buttonSize="big"
                                onClick={() => {
                                    setRedSlipPreviewModal(parseDataForSubmit(form.getFieldsValue(), true))
                                    setModalRedSlipPreview(true)
                                }}
                            >
                                {t('プレビュー')}
                            </InsButton>
                        </Col>
                        <Col className={'btn-update-project-bottom'}>
                            <InsButton
                                buttonStyle="outline"
                                buttonSize="big"
                                centered
                                width="140px"
                                onClick={() => {
                                    history.push(
                                        `${Routes.private.business.PROJECT_DETAIL.path.replace(':id', project.id)}`
                                    )
                                }}
                            >
                                {t('common:Cancel')}
                            </InsButton>

                            <InsButton
                                buttonStyle="primary"
                                buttonSize="big"
                                centered
                                width="140px"
                                isSubmitButton={true}
                            >
                                {isEdit ? t('common:Save') : t('common:Save')}
                            </InsButton>
                        </Col>
                    </Row>
                </InsFloatBar>
            </Form>
        </div>
    )
}

export default RedSlipForm

RedSlipForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    project: PropTypes.object.isRequired,
    response: PropTypes.func,
    sale: PropTypes.object.isRequired,
    redSlip: PropTypes.object.isRequired,
}
