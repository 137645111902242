import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { dateToStr } from 'helpers/date.helper'
import { getClassificationItemName } from 'helpers/classification.helper'
import { formatNumber } from 'helpers/number.helper'
import { DescriptionIcon, SubdirectoryArrowRightIcon, TruckIcon } from 'components/InsIcon'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import InsNoteAffix from 'components/InsNoteAffix'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import { InsBlockLoader } from 'components/InsLoader'
import InstructionTypeEnum from 'enums/instruction_type.enum'
import { isHeadline } from 'helpers/detail.helper'
import InsTextArea from 'components/InsTextArea'
import { isEmpty } from 'helpers/string.helper'

const InstructionPrintTemplate = ({
    project,
    instructionType,
    instruction,
    hasPagination = false,
    filterDetailType,
    slotPerPage = 9,
    slotFirstPage = 7,
    splitByType = false,
    hideColumns = [],
    titleText,
}) => {
    const { t } = useTranslation()

    const isOutsource = instruction.instruction_classification === InstructionTypeEnum.OUTSOURCE.value

    const renderTags = instruction => {
        const tags = [
            {
                text: t('column:Is included'),
                isTrue: instruction.is_included,
            },
            {
                text: t('column:Is hold sales'),
                isTrue: instruction.is_hold_sales,
            },
            {
                text: t('column:Specified sales date'),
                isTrue: instruction.is_specify_sales_date,
            },
            {
                text: t('column:Serial number sales'),
                isTrue: instruction.is_serial_number_sales,
            },
            {
                text: t('column:Is additional included'),
                isTrue: instruction.is_additional_included,
            },
            {
                text: t('column:Is replacement'),
                isTrue: instruction.is_replacement,
            },
            {
                text: t('column:Is time service'),
                isTrue: instruction.is_time_service,
            },
            {
                text: t('column:Invoice no notice'),
                isTrue: instruction.is_invoice_no_notice,
            },
        ]
        return (
            <>
                {tags.map(
                    (t, index) =>
                        !!t.isTrue && (
                            <div className="i-tag-wrap" key={`tag_${index}`}>
                                <div className="i-tag-text">{t.text}</div>
                            </div>
                        )
                )}
            </>
        )
    }

    const renderRightPageNumWrap = (pageNum, project, instruction) => {
        pageNum = pageNum < 10 ? '0' + pageNum : pageNum

        return (
            <div className="p-right-page-num-wrap">
                <div className="p-right-page-num">
                    <div className="p-page-num">
                        {'PAGE - '}
                        {pageNum}
                    </div>
                    <table className="p-right-table">
                        <tbody>
                            <tr>
                                <th>
                                    {t('column:Project code')}
                                    <span>{':'}</span>
                                </th>
                                <td>
                                    <span className="p-bold">{project.project_code}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t('column:Instruction code')}
                                    <span>{':'}</span>
                                </th>
                                <td>{instruction.instruction_code}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const getTitleManual = pageData => {
        return (
            getClassificationItemName(
                instruction.instruction_classification === 1
                    ? ClassificationTypeEnum.IN_HOUSE_PROCESSED.value
                    : ClassificationTypeEnum.OUTSIDE_PROCESSED.value,
                pageData[0].details[0].processed_product_classification
            ) || ''
        )
    }

    const renderPageTitleWrap = pageData => {
        return (
            <div className="i-page-title-wrap">
                <div className="i-page-title-text">{`${titleText || getTitleManual(pageData)}${
                    instruction.instruction_classification === 1 ? '加工' : ''
                }指示書`}</div>
                <div className="i-tags-wrap">{renderTags(instruction)}</div>
            </div>
        )
    }

    const renderCustomer = instruction => {
        const customer = instruction.customer
        if (!customer) return false
        return (
            <>
                <span className="p-bold mr-16">{customer.customer_name}</span>
                <span className="p-bold mr-16">{'ご担当者'}</span>
                <span className="p-bold mr-16">
                    {getClassificationItemName(
                        ClassificationTypeEnum.CUSTOMER_TITLE.value,
                        instruction.customer_pic_title
                    )}
                </span>
                {customer.phone_number && <span>{`TEL：${customer.phone_number}`}</span>}
            </>
        )
    }
    const renderShipper = instruction => {
        const shipper = instruction.shipper
        if (!shipper) return false
        return (
            <>
                <span className="p-bold">
                    {shipper.client_name}
                    {'　半田　様'}
                </span>
                <br />
                <span>
                    {'〒'}
                    {shipper.post_code}
                    {'　'}
                    {getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, shipper.prefecture_code)}
                    {shipper.address1} {shipper.address2 || ''}
                </span>
                <br />
                {shipper.phone_number && <span>{`TEL：${shipper.phone_number}`}</span>}
            </>
        )
    }
    const renderPageTable1 = isFirstPage => {
        return (
            <>
                {isFirstPage && (
                    <div className="i-page-table-1">
                        <table className="p-table">
                            <tbody>
                                <tr className="p-border p-border-top-dark">
                                    <th className="p-h-48 p-bg-50 p-border-left-0">{t('column:Customer name')}</th>
                                    <td colSpan={5}>{renderCustomer(instruction)}</td>
                                </tr>
                                <tr className="p-border">
                                    <th className="p-w-114 p-bg-50 p-border-left-0">{t('column:Arrival date')}</th>
                                    <td className="p-w-169 p-bold">
                                        <span>
                                            {dateToStr(instruction.arrival_date, 'M月 DD日')}
                                            {'　'}
                                        </span>
                                        <span>
                                            {getClassificationItemName(
                                                ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                instruction.arrival_time
                                            )}
                                        </span>
                                    </td>
                                    {isOutsource ? (
                                        <>
                                            <th className="p-w-114 p-bg-50">{t('受注日')}</th>
                                            <td className="p-w-179 p-nowrap">
                                                <span className="p-bold mr-16">
                                                    {dateToStr(instruction.received_order_date)}
                                                </span>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <th className="p-w-114 p-bg-50">{t('column:Delivery type')}</th>
                                            <td className="p-w-179 p-nowrap">
                                                <span className="p-bold mr-16">
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.DELIVERY_CATEGORY_1.value,
                                                        instruction.delivery_type
                                                    )}
                                                </span>
                                                {instruction.delivery_type === '1' && instruction.transport_type && (
                                                    <span>{`(${getClassificationItemName(
                                                        ClassificationTypeEnum.DELIVERY_CATEGORY_2.value,
                                                        instruction.transport_type
                                                    )}宅急便)`}</span>
                                                )}
                                            </td>
                                        </>
                                    )}
                                    <th className="p-w-134 p-bg-50">{t('Inasa representative')}</th>
                                    <td className="p-w-198">{instruction.pic && instruction.pic.pic_name}</td>
                                </tr>
                                {isOutsource ? (
                                    <>
                                        <tr className="p-border">
                                            <th className="p-w-114 p-bg-50 p-border-left-0">{t('出荷日')}</th>
                                            <td className="p-w-169 p-bold">
                                                <span>
                                                    {dateToStr(instruction.shipping_date, 'M月 DD日')}
                                                    {'　'}
                                                </span>
                                                <span>
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                        instruction.shipping_time
                                                    )}
                                                </span>
                                            </td>
                                            <th className="p-w-114 p-bg-50">{t('発注書番号')}</th>
                                            <td className="p-w-179">
                                                <span className="p-bold mr-16 p-nowrap">
                                                    {instruction.place_order_code}
                                                </span>
                                            </td>
                                            <th className="p-w-134 p-bg-50">{t('発注タイミング')}</th>
                                            <td className="p-w-198">
                                                <span className="p-bold p-nowrap">
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.OUTSOURCE_TIMING.value,
                                                        instruction.outsource_timing
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr className="p-border">
                                            <th className="p-w-114 p-bg-50 p-border-left-0">{t('納品日')}</th>
                                            <td className="p-w-169 p-bold">
                                                <span>
                                                    {dateToStr(instruction.delivery_date, 'M月 DD日')}
                                                    {'　'}
                                                </span>
                                                <span>
                                                    {getClassificationItemName(
                                                        ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                        instruction.delivery_time
                                                    )}
                                                </span>
                                            </td>
                                            <th className="p-w-114 p-bg-50">{t('発注先')}</th>
                                            <td className="p-w-179" colSpan={3}>
                                                <span className="p-bold mr-16 p-nowrap">
                                                    {instruction.outsource?.outsource_name}
                                                </span>
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr className="p-border">
                                        <th className="p-w-114 p-bg-50 p-border-left-0">
                                            {t('column:Processing arrival date')}
                                        </th>
                                        <td className="p-w-169 p-bold">
                                            <span>
                                                {dateToStr(instruction.processing_arrival_date, 'M月 DD日')}
                                                {'　'}
                                            </span>
                                            <span>
                                                {getClassificationItemName(
                                                    ClassificationTypeEnum.INSTRUCTION_ARRIVAL_DATE.value,
                                                    instruction.processing_arrival_time
                                                )}
                                            </span>
                                        </td>
                                        <th className="p-w-114 p-bg-50">{t('column:Delivery date')}</th>
                                        <td className="p-w-179">
                                            <span className="p-bold mr-16 p-nowrap">
                                                {dateToStr(instruction.delivery_date, 'M月 DD日')}
                                                {'　'}
                                                {instruction.delivery_type === '2'
                                                    ? '←持ち出し'
                                                    : instruction.delivery_type === '3'
                                                    ? 'ご来社'
                                                    : '出荷'}
                                            </span>
                                        </td>
                                        <th className="p-w-134 p-bg-50">{t('column:Scheduled arrival date')}</th>
                                        <td className="p-w-198">
                                            <span className="p-bold p-nowrap">
                                                {dateToStr(instruction.scheduled_arrival_date, 'M月 DD日')}
                                                {'　'}
                                            </span>
                                            <span className="p-bold p-nowrap">
                                                {getClassificationItemName(
                                                    ClassificationTypeEnum.INSTRUCTION_ARRIVAL_SCHEDULED.value,
                                                    instruction.scheduled_arrival_time
                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                <tr className="p-border">
                                    <th className="p-w-114 p-bg-50 p-border-left-0">
                                        {t('column:Specified sales date')}
                                    </th>
                                    <td className="p-w-169 p-bold">
                                        <span>{dateToStr(instruction.specified_sales_date, 'M月 DD日')}</span>
                                    </td>
                                    <th className="p-w-114 p-bg-50">{t('column:Serial number sales')}</th>
                                    <td className="p-w-179" colSpan={isOutsource ? 3 : 1}>
                                        <span>{instruction.serial_number_sales}</span>
                                    </td>
                                    {!isOutsource && (
                                        <>
                                            <th className="p-w-134 p-bg-50">{'送り状お知らせ'}</th>
                                            <td className="p-w-198 p-nowrap">
                                                {getClassificationItemName(
                                                    ClassificationTypeEnum.INSTRUCTION_NO_INVOICE.value,
                                                    instruction.invoice_no_notice
                                                )}
                                            </td>
                                        </>
                                    )}
                                </tr>
                                <tr className="p-border p-border-bottom-dark">
                                    <th className="p-bg-50 p-border-left-0">{t('column:Shipper')}</th>
                                    <td colSpan={5} className="p-note">
                                        {renderShipper(instruction)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </>
        )
    }

    const renderExtItem = (itemValue, label, clfValue) => {
        return (
            <>
                {itemValue && (
                    <div className="p-ext-item">
                        <span>{`${label}：`}</span>
                        <span className="p-bold">
                            {clfValue !== undefined ? getClassificationItemName(clfValue, itemValue) : itemValue}
                        </span>
                    </div>
                )}
            </>
        )
    }

    const renderTableDetailsWrap = (pageData, no_delivery_destination) => {
        return (
            <div className="i-table-details-wrap">
                <div className="i-table-detail">
                    {pageData.map((destination, index) => (
                        <Fragment key={index}>
                            {!no_delivery_destination && (
                                <div className="i-table-detail-shipping for-instruction">
                                    <div className="i-table-shipping-type">
                                        <span className="p-truck mr-8">
                                            <TruckIcon size={24} />
                                        </span>
                                        <span className="p-shipping-text">
                                            {`${t('column:Delivery destination')} :`}
                                        </span>
                                    </div>
                                    <div className="i-table-shipping-detail">
                                        <div className="i-table-shipping-detail-line1">
                                            <span className="p-bold mr-16">
                                                {destination.delivery_destination_name}
                                            </span>
                                            <span className="p-bold mr-16">
                                                {destination.delivery_destination_pic_name}
                                            </span>
                                            <span className="p-bold mr-16">
                                                {destination.delivery_destination_pic_title}
                                            </span>
                                        </div>
                                        <div className="i-table-shipping-detail-line2">
                                            <span className="mr-24">{destination.delivery_destination_address}</span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="mb-20">
                                <table className="p-table-2">
                                    <thead>
                                        <tr>
                                            <th>{t('common:Title')}</th>
                                            <th>{t('column:Spec')}</th>
                                            <th>{t('column:Size')}</th>
                                            <th>{t('column:Quantity')}</th>
                                            <th>{t('column:Unit')}</th>
                                            {hideColumns.indexOf('unit_price') === -1 && (
                                                <th>{t('column:Unit price')}</th>
                                            )}
                                            {hideColumns.indexOf('unit_cost') === -1 && <th>{t('column:Cost')}</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {destination.details.map(d => (
                                            <Fragment key={d.id}>
                                                <tr>
                                                    <td>
                                                        <span className="p-bold">
                                                            <InsNoteAffix
                                                                note={
                                                                    <InsTextArea
                                                                        className="color-gray-678 textarea-auto-size text-area-preview"
                                                                        autoSize={true}
                                                                        disabled={true}
                                                                        allowClear={false}
                                                                        defaultValue={d.note}
                                                                    />
                                                                }
                                                                showCorner={!hasPagination && !isEmpty(d.note)}
                                                            >
                                                                <span>{d.title}</span>
                                                            </InsNoteAffix>
                                                        </span>
                                                    </td>
                                                    <td>{d.spec}</td>
                                                    <td>{d.size}</td>
                                                    <td>{d.quantity}</td>
                                                    <td>
                                                        {getClassificationItemName(
                                                            ClassificationTypeEnum.PRODUCT_UNIT.value,
                                                            d.unit
                                                        )}
                                                    </td>
                                                    {hideColumns.indexOf('unit_price') === -1 && (
                                                        <td>{formatNumber(d.unit_price)}</td>
                                                    )}
                                                    {hideColumns.indexOf('unit_cost') === -1 && (
                                                        <td>{formatNumber(d.unit_cost)}</td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td colSpan={7} className="with-dashed">
                                                        <SubdirectoryArrowRightIcon size={16} className="p-sub-dir" />
                                                        <div className="p-extends">
                                                            {renderExtItem(
                                                                d.lam_thickness,
                                                                'ラミ厚',
                                                                ClassificationTypeEnum.LAMINATED_PRODUCT_THICKNESS.value
                                                            )}
                                                            {renderExtItem(
                                                                d.mimi,
                                                                'ﾐﾐ幅',
                                                                ClassificationTypeEnum.PROCESSED_PRODUCT_MIMI.value
                                                            )}
                                                            {renderExtItem(d.rounded_corners, '角丸')}
                                                            {renderExtItem(d.suji_oshi, 'ｽｼﾞ押し')}
                                                            {renderExtItem(d.cutting, '断裁')}
                                                            {renderExtItem(
                                                                d.media,
                                                                'メディア',
                                                                d.processed_product_classification === '3'
                                                                    ? ClassificationTypeEnum
                                                                          .LARGE_FORMAT_PROCESSED_MEDIA.value
                                                                    : d.processed_product_classification === '4'
                                                                    ? ClassificationTypeEnum.SOLVENT_PROCESSED_MEDIA
                                                                          .value
                                                                    : d.processed_product_classification === '5'
                                                                    ? ClassificationTypeEnum.PANEL_PROCESSED_MEDIA.value
                                                                    : undefined
                                                            )}
                                                            {renderExtItem(
                                                                d.printing,
                                                                '印刷',
                                                                ClassificationTypeEnum.WORKPIECE_PRINTING.value
                                                            )}
                                                            {renderExtItem(
                                                                d.printing_paper,
                                                                '用紙',
                                                                ClassificationTypeEnum.WORKPIECE_PAPER.value
                                                            )}
                                                            {renderExtItem(
                                                                d.printing_form,
                                                                '形',
                                                                ClassificationTypeEnum.WORKPIECE_SHAPE.value
                                                            )}
                                                            {renderExtItem(
                                                                d.cold,
                                                                'ゴールド',
                                                                ClassificationTypeEnum.PROCESSED_GOLD.value
                                                            )}
                                                            {renderExtItem(
                                                                d.oberami,
                                                                'オーパーラミ',
                                                                ClassificationTypeEnum.WORKPIECE_OVER_LAMINATION.value
                                                            )}
                                                            {renderExtItem(
                                                                d.panel_thickness,
                                                                'パネル厚',
                                                                ClassificationTypeEnum.WORKPIECE_PANEL_THICKNESS.value
                                                            )}
                                                            {renderExtItem(
                                                                d.product_name,
                                                                '商品名',
                                                                d.processed_product_classification === '1'
                                                                    ? ClassificationTypeEnum
                                                                          .CLIMBING_PRODUCT_NAME_CATEGORY.value
                                                                    : d.processed_product_classification === '2'
                                                                    ? ClassificationTypeEnum
                                                                          .CHAIR_COVER_PRODUCT_NAME_CATEGORY.value
                                                                    : d.processed_product_classification === '3'
                                                                    ? ClassificationTypeEnum
                                                                          .TRIANGLE_FLAG_PRODUCT_NAME_CATEGORY.value
                                                                    : undefined
                                                            )}
                                                            {renderExtItem(
                                                                d.material,
                                                                '素材',
                                                                ClassificationTypeEnum.PROCESSED_MATERIAL.value
                                                            )}
                                                            {renderExtItem(
                                                                d.chichi,
                                                                'チチ',
                                                                ClassificationTypeEnum.PROCESSED_PRODUCT_CLASS.value
                                                            )}
                                                            {renderExtItem(
                                                                d.heat_cut,
                                                                'ヒートカット',
                                                                ClassificationTypeEnum.PROCESSED_PRODUCT_HEAT_CUT.value
                                                            )}
                                                            {renderExtItem(
                                                                d.mimaki,
                                                                '三巻',
                                                                ClassificationTypeEnum.THREE_PIECE_WORKPIECE.value
                                                            )}
                                                            {renderExtItem(d.bias, 'バイアス')}
                                                            {renderExtItem(d.back, '背面')}
                                                            {renderExtItem(
                                                                d.pocket,
                                                                'ポケット',
                                                                ClassificationTypeEnum.WORKPIECE_POCKET.value
                                                            )}
                                                            {renderExtItem(
                                                                d.bag_stopper,
                                                                '袋止め',
                                                                ClassificationTypeEnum.WORKPIECE_BAG_STOP.value
                                                            )}
                                                            {renderExtItem(
                                                                d.cap,
                                                                'キャップ',
                                                                ClassificationTypeEnum.WORKPIECE_CAP.value
                                                            )}
                                                            {renderExtItem(
                                                                d.pole_material,
                                                                'ポール素材',
                                                                ClassificationTypeEnum.WORKPIECE_POLE_MATERIAL.value
                                                            )}
                                                            {renderExtItem(
                                                                d.pole_length,
                                                                'ポール長さ',
                                                                ClassificationTypeEnum.WORKPIECE_POLE_LENGTH.value
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="p-note-wrap">
                                                            <DescriptionIcon
                                                                size={20}
                                                                className="p-description-icon mr-8"
                                                            />
                                                            <span className="p-bold">{`${t('column:Memo')}：`}</span>
                                                            <span>{d.memo}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
        )
    }

    const renderNoteGroup = (instruction, isLastPage) => {
        return (
            <>
                {isLastPage && (
                    <div className="p-note-group">
                        <label className="p-bold">{'備考'}</label>
                        <div className="p-note-group-textarea">{instruction.note}</div>
                    </div>
                )}
            </>
        )
    }

    const paginate = (instruction, filterDetailType) => {
        // anti-&ref
        const destinations = instruction.delivery_destinations
        let pages = []

        // no pagination
        if (!hasPagination) {
            // with tricks
            slotPerPage = 9999
            slotFirstPage = 9999
        }

        // 1 slot: (1 destination + thead) ~ (1 detail)
        let maxSlot = slotFirstPage
        let slot = 0

        // with destinations
        if (destinations.length > 0) {
            let chunkDestinations = []

            for (const [i, destination] of destinations.entries()) {
                let tmpDestination = Object.assign({}, destination, { details: [] })
                slot++

                let filterDetails = filterDetailType
                    ? destination.details.filter(detail => filterDetailType(detail))
                    : destination.details
                for (const [j, detail] of filterDetails.entries()) {
                    tmpDestination.details.push(detail)
                    slot++

                    // || (fix case: 5 slot + ( 1 des + x detail))
                    if (slot >= maxSlot || (j === filterDetails.length - 1 && slot === maxSlot - 1)) {
                        chunkDestinations.push(tmpDestination)
                        pages.push(chunkDestinations)
                        maxSlot = slotPerPage
                        chunkDestinations = []

                        tmpDestination = Object.assign({}, destination, { details: [] })
                        slot = j === filterDetails.length - 1 ? 0 : 1
                    }
                }

                if (tmpDestination.details.length > 0) {
                    chunkDestinations.push(tmpDestination)
                }

                if (i === destinations.length - 1 && chunkDestinations.length > 0) {
                    pages.push(chunkDestinations)
                    maxSlot = slotPerPage
                    chunkDestinations = []
                }
            }
            return { pages: pages }
        } else {
            // only details
            let details = filterDetailType
                ? instruction.details.filter(detail => filterDetailType(detail))
                : instruction.details

            let chunkDetails = []
            for (const [, detail] of details.entries()) {
                slot++
                chunkDetails.push(detail)
                if (slot >= maxSlot) {
                    pages.push([{ details: chunkDetails }])
                    slot = 0
                    maxSlot = slotPerPage
                    chunkDetails = []
                }
            }
            if (chunkDetails.length > 0) {
                pages.push([{ details: chunkDetails }])
            }
            return { pages: pages, no_delivery_destination: true }
        }
    }

    // render
    const renderPages = filterDetailType => {
        const { pages, no_delivery_destination } = paginate(instruction, filterDetailType)
        return (
            <>
                {pages.map((pageData, index) => (
                    <Fragment key={index}>
                        {renderRightPageNumWrap(index + 1, project, instruction)}
                        {renderPageTitleWrap(pageData)}
                        {renderPageTable1(index === 0)}
                        {renderTableDetailsWrap(pageData, no_delivery_destination)}
                        {renderNoteGroup(instruction, index === pages.length - 1)}
                        {index < pages.length - 1 && <div className="page-break" />}
                    </Fragment>
                ))}
            </>
        )
    }

    const recursiveRender = () => {
        let group = []
        instruction.details.map(d => {
            if (!isHeadline(d) && group.indexOf(d.processed_product_classification) < 0) {
                group.push(d.processed_product_classification)
            }

            return null
        })
        return group.map((type, index) => {
            return (
                <Fragment key={index}>
                    {renderPages(detail => !isHeadline(detail) && detail.processed_product_classification === type)}
                    {index < group.length - 1 && <div className="page-break" />}
                </Fragment>
            )
        })
    }
    if (!instruction) {
        return <InsBlockLoader />
    } else {
        return splitByType ? recursiveRender() : renderPages(filterDetailType)
    }
}

export default InstructionPrintTemplate

InstructionPrintTemplate.propTypes = {
    project: PropTypes.object.isRequired,
    instructionType: PropTypes.number,
    instruction: PropTypes.object,
    hasPagination: PropTypes.bool,
    filterDetailType: PropTypes.func,
    slotPerPage: PropTypes.number,
    slotFirstPage: PropTypes.number,
    splitByType: PropTypes.bool,
    hideColumns: PropTypes.array,
    titleText: PropTypes.string,
}
