import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
import { logout, selectUsername, selectUserPicName, selectUserPicNameKana } from 'modules/common/auth/auth.slice'
import logo from 'assets/images/logo.png'
import InsLinkDropdown from 'components/dropdown/InsLinkDropdown'
import { createSelector } from '@reduxjs/toolkit'
import Routes from 'routes'
import { useTranslation } from 'react-i18next'
import history from 'helpers/history.helper'
import { AccountCircleIcon, SettingIcon } from 'components/InsIcon'
import { checkAdmin } from 'helpers/permission.helper'
import useQuery from 'helpers/query_params.helper'
import NavigationEnum from 'enums/navigation.enum'

export const NavigationBar = ({ displayName, logout, permissions }) => {
    const { t } = useTranslation()
    let query = useQuery()
    const onAccountDropdownSelected = item => {
        switch (item.key) {
            case 'account':
                history.push(Routes.private.master.ACCOUNT_SETTING.path)
                break
            case 'display': {
                history.push(Routes.private.master.ACCOUNT_SETTING_EDIT.path + '?user_setting=true')
                break
            }
            case 'logout':
                logout()
                break
            default:
                console.log(item)
        }
    }

    const manageMenuHandler = item => {
        switch (item.key) {
            case 'customer':
                history.push(Routes.private.master.CUSTOMER_LIST.path)
                break
            case 'user':
                history.push(Routes.private.master.USER_LIST.path)
                break
            case 'outsource':
                history.push(Routes.private.master.OUTSOURCE_LIST.path)
                break
            case 'product':
                history.push(Routes.private.master.PRODUCT_LIST.path)
                break
            // case 'product-cost':
            //     history.push(Routes.private.master.PRODUCT_COST_LIST.path)
            //     break
            case 'supplier':
                history.push(Routes.private.master.SUPPLIER_LIST.path)
                break
            case 'team':
                history.push(Routes.private.master.TEAM_LIST.path)
                break
            case 'company':
                history.push(Routes.private.master.COMPANY_LIST.path)
                break
            case 'inventory':
                history.push(Routes.private.master.STOCK_LIST.path)
                break
            case 'action-log':
                history.push(Routes.private.master.ACTION_LOG.path)
                break
            case 'shipping-address':
                history.push(Routes.private.master.SHIPPING_ADDRESS_LIST.path)
                break
            // case 'client':
            //     history.push(Routes.private.master.CLIENT_LIST.path)
            //     break
            case 'classification':
                history.push(Routes.private.master.CLASSIFICATION_LIST.path)
                break
            default:
                console.log(item)
        }
    }

    const checkHidden = permissionName => {
        return permissions.find(p => p.authority_name === permissionName)
    }

    return (
        <div className="navigation-bar">
            <Link to="/" className="navigation-logo">
                <img src="/logo192.png" style={{ width: '70%' }} alt="TM" />
            </Link>
            <ul className="navigation-items">
                <li className="navigation-item">
                    <NavLink
                        to={Routes.private.business.PROJECT.path}
                        isActive={(match, location) => {
                            let queryFrom = query.get('from')
                            let isFromList =
                                queryFrom === NavigationEnum.LIST_ESTIMATIONS ||
                                queryFrom === NavigationEnum.LIST_INSTRUCTIONS ||
                                queryFrom === NavigationEnum.LIST_PLACE_ORDERS ||
                                queryFrom === NavigationEnum.LIST_SALES ||
                                queryFrom === NavigationEnum.LIST_RED_SLIPS ||
                                queryFrom === NavigationEnum.LIST_INVOICES
                            if (
                                (match && !query.get('from')) ||
                                isFromList ||
                                [
                                    '/estimations',
                                    '/instructions',
                                    '/place-orders',
                                    '/sales',
                                    '/invoices',
                                    '/red-slips',
                                ].find(p => location.pathname.startsWith(p))
                            )
                                return true
                            // if (match) return true
                            // const pathname = location.pathname
                        }}
                    >
                        {t('navigation:Projects')}
                    </NavLink>
                </li>
                <li className="navigation-item">
                    <NavLink
                        to={Routes.private.business.SUMMARY_INVOICE.path}
                        isActive={(match, location) => {
                            if (match) return true
                            return query.get('from') === NavigationEnum.SUMMARY_INVOICES
                        }}
                    >
                        {t('navigation:Summary invoice')}
                    </NavLink>
                </li>
                <li className="navigation-item">
                    <NavLink
                        to="/shipments"
                        isActive={(match, location) => {
                            if (match) return true
                            return query.get('from') === NavigationEnum.SHIPMENTS
                        }}
                    >
                        {t('navigation:Shipments')}
                    </NavLink>
                </li>
                {!checkHidden('PAYMENT_HIDDEN') && (
                    <li className="navigation-item">
                        <NavLink
                            to={Routes.private.business.PAYMENT.path}
                            isActive={(match, location) => {
                                if (match) return true
                                return query.get('from') === NavigationEnum.PAYMENTS
                            }}
                        >
                            {t('navigation:Payments')}
                        </NavLink>
                    </li>
                )}
                {!checkHidden('REPORT_HIDDEN') && (
                    <li className="navigation-item">
                        <NavLink to="/reports">{t('navigation:Reports')}</NavLink>
                    </li>
                )}
                <li className="navigation-item align-right">
                    <InsLinkDropdown
                        className="mr-16"
                        trigger={['click']}
                        icon={<AccountCircleIcon size={24} />}
                        text={displayName}
                        onSelect={onAccountDropdownSelected}
                        options={[
                            { key: 'account', text: 'アカウント管理' },
                            { key: 'display', text: '表示設定' },
                            { key: 'logout', text: t('common:Logout'), className: 'color-pantone-213-c' },
                        ]}
                    />
                    <InsLinkDropdown
                        icon={<SettingIcon size={24} />}
                        trigger={['click']}
                        text={t('navigation:Data management')}
                        placement="bottomRight"
                        onSelect={key => manageMenuHandler(key)}
                        options={[
                            {
                                key: 'customer',
                                text: t('navigation:{{entity}} management', { entity: t('entity:Customer') }),
                            },
                            {
                                key: 'product',
                                text: t('navigation:{{entity}} management', { entity: t('entity:Product') }),
                            },
                            // {
                            //     key: 'product-cost',
                            //     text: t('navigation:{{entity}} management', { entity: t('entity:ProductCost') }),
                            // },
                            { key: 'outsource', text: t('navigation:Outsource management') },
                            { key: 'supplier', text: '仕入先管理' },
                            { key: 'shipping-address', text: '配送先管理' },
                            // { key: 'client', text: t('navigation:{{entity}} list', { entity: t('entity:Client') }) },
                            {
                                key: 'classification',
                                text: t('navigation:{{entity}} list', { entity: t('entity:Classification') }),
                            },
                            checkAdmin() && {
                                key: 'admin-menu',
                                text: '管理者メニュー',
                                children: [
                                    { key: 'user', text: 'ユーザー管理' },
                                    { key: 'team', text: 'チーム管理' },
                                    // { key: 'setting', text: 'システム設定' },
                                    { key: 'inventory', text: '在庫管理' },
                                    { key: 'action-log', text: t('entity:ActionLog') },
                                ],
                            },
                            {
                                key: 'company',
                                text: '会社情報',
                                // children: [{ key: 'company', text: t('navigation:Company information') }],
                            },
                        ]}
                    />
                </li>
            </ul>
        </div>
    )
}

const selectDisplayName = createSelector(
    [selectUsername, selectUserPicName, selectUserPicNameKana],
    (username, picName, picNameKana) => (picName ? picName : picNameKana ? picNameKana : username)
)

const mapState = state => ({
    displayName: selectDisplayName(state),
    permissions: state.auth.user.authorities,
})
const mapDispatch = { logout }
export default connect(mapState, mapDispatch)(NavigationBar)

NavigationBar.propTypes = {
    displayName: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
}
