import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, withRouter } from 'react-router-dom'
import moment from 'moment'
import { Col, Form, Row, Select } from 'antd'
import InsButton from 'components/InsButton'
import InsFloatBar from 'components/InsFloatBar'
import InsInput from 'components/InsInput'
import InsTextArea from 'components/InsTextArea'
import ClassificationTypeEnum from 'enums/classification_type.enum'
import { getClassificationItemName, getClassificationsByType } from 'helpers/classification.helper'
import InsSelect from 'components/select/InsSelect'
import InsDatePicker from 'components/datepicker/InsDatePicker'
import CustomerSelect from 'modules/master/customer/CustomerSelect'
import InsCustomDatePicker from 'components/datepicker/InsCustomDatePicker'
import { CalendarTodayIcon, PreviewIcon } from 'components/InsIcon'
import { SENDER_CODE } from 'constants/classification.constant'
import api from 'helpers/api.helper'
import { APP_URLS } from 'constants/url.constant'
import UserSelect from 'modules/master/user/UserSelect'
import InsInputNumber from 'components/InsInputNumber'
import history from 'helpers/history.helper'
import EstimationDragAndDrop from 'modules/business/estimation/form/EstimationDragAndDrop'
import EstimationDetailAddButton from 'modules/business/estimation/form/EstimationDetailAddButton'
import { setDefaultState, parseData, setColumnMode } from 'modules/business/estimation/estimation_detail.slice'
import { showWarning } from 'helpers/notification.helper'
import { calculateInStock, calculateTotal } from 'helpers/calculate.helper'
import EstimationPreviewModal from 'modules/business/estimation/EstimationPreviewModal'
import InsAutoComplete from 'components/InsAutoComplete'
import { can } from 'helpers/permission.helper'
import NoPermission from 'modules/common/ui/NoPermission'
import RouterPrompt from 'modules/common/ui/RouterPrompt'
import InsModal from 'components/InsModal'
import Routes from 'routes'
import { isHeadline } from 'helpers/detail.helper'
import StockListInsufficient from 'modules/master/stock/StockListInsufficient'
import { formatNumber } from 'helpers/number.helper'

const EstimationForm = ({
    isEdit,
    initialValues = {},
    onSubmit,
    currentUser,
    project = {},
    details,
    detailsOrder,
    setDefaultState,
    parseData,
    updatedDetail,
    setColumnMode,
    columnMode,
    projectForm,
    projectCreateDoc,
}) => {
    const { t } = useTranslation()
    const [form] = projectForm ? [projectForm] : Form.useForm()
    const id = initialValues.id
    const [modalPreviewVisible, setModalPreviewVisible] = useState(false)
    const [isValueChange, setIsValueChange] = useState(!isEdit)
    const [companies, setCompanies] = useState([])
    const [confirmCredit, setConfirmCredit] = useState(false)
    const [confirmSave, setConfirmSave] = useState(false)
    const routerPromptRef = useRef()
    const location = useLocation()
    const [oosProducts, setOosProducts] = useState({
        isOutOfStock: false,
        data: {},
    })

    const parseDataForUpdate = () => {
        setDefaultState()
        setColumnMode(initialValues.estimation_classification || 1)
        id && parseData(initialValues.detail)
    }
    useEffect(() => {
        if (!projectCreateDoc) {
            parseDataForUpdate()
        }
    }, [project])

    useEffect(() => {
        parseDataForUpdate()
    }, [])

    // copy sender
    let useSenderCopy = false
    if (!isEdit) {
        if (initialValues.sender_id && initialValues.sender_id === project?.customer?.estimation_sender_code) {
            useSenderCopy = true
        } else {
            delete initialValues['sender_id']
            delete initialValues['sender_name']
            delete initialValues['sender_address']
        }
    }

    const project_code = project.project_code
    initialValues = Object.assign(
        {
            estimation_classification: 1,
            estimation_code: project_code + '-MK-' + (project?.count_estimation || 0),
            detail: [],
            sender_id: project?.customer?.estimation_sender_code || SENDER_CODE.ESTIMATION_DEFAULT,
            sender_name: project?.customer?.estimation_sender_name || '',
            sender_address: project?.customer?.estimation_sender_address || '',
            gross_profit: 0,
            subtotal: 0,
            consumption_tax: 0,
            total: 0,
            pic_id: project?.pic_id || currentUser.id,
            pic: project?.pic || currentUser,
            // auto input
            customer_id: project?.customer?.id,
            customer: project?.customer,
            customer_pic_title: project?.customer?.customer_title,
            customer_company_title: project?.customer?.customer_title,
            customer_pic_name: (project?.customer?.customer_pic || '').split(',')[0],
            estimation_name: project?.project_name,
        },
        initialValues
    )

    const [customer, setCustomer] = useState(
        (projectForm ? initialValues.estimation_customer : initialValues.customer) || {}
    )
    // clone - generate project_code -> estimation_code
    useEffect(() => {
        if (!initialValues.estimation_code.startsWith(project_code)) {
            form.setFieldsValue({ estimation_code: project_code + '-MK-' + 1 })
        }
    }, [project.project_code])

    // set estimation_classification via place_order
    if (initialValues.place_order_classification) {
        initialValues.estimation_classification = initialValues.place_order_classification
    }
    // set estimation_classification via sale
    if (initialValues.sale_classification) {
        initialValues.estimation_classification = initialValues.sale_classification
    }

    initialValues.project_id = project.id
    initialValues.issued_date = initialValues.issued_date
        ? moment.utc(initialValues.issued_date, 'YYYY-MM-DD')
        : moment.utc(moment().format('YYYY-MM-DD'))

    const dateFormat = 'YYYY年M月DD日'

    const handlerCustomerChange = id => {
        const getCustomer = async id => {
            const data = await api.get(APP_URLS.CUSTOMERS_DETAIL.replace(':id', id))
            if (data) {
                setCustomer(data)

                form.setFieldsValue({ sender_id: data.estimation_sender_code })
                if (data.estimation_sender_code === SENDER_CODE.ESTIMATION_OTHER) {
                    form.setFieldsValue({
                        sender_name: data.estimation_sender_name,
                        sender_address: data.estimation_sender_address,
                    })
                } else {
                    // smt here
                    const company = getCompanyByCode(data.estimation_sender_code)
                    form.setFieldsValue({
                        sender_name: company.company_name,
                        sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
                    })
                }
                setResult(data.customer_pic ? data.customer_pic.split(',') : [])
                // set title
                form.setFieldsValue({
                    customer_pic_name: '',
                    customer_pic_title: data.customer_title,
                    customer_company_title: data.customer_title,
                })
            }
        }
        getCustomer(id)
    }

    const getCompanies = async () => {
        const data = await api.get(APP_URLS.COMPANIES)
        if (data && data.list) {
            setCompanies(data.list)
            // special
            if (!isEdit && initialValues.sender_id !== SENDER_CODE.ESTIMATION_OTHER) {
                // set title
                const company = getCompanyByCode(initialValues.sender_id, data.list)
                form.setFieldsValue({
                    sender_name: company.company_name,
                    sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
                })
            }
        }
    }
    useEffect(() => {
        getCompanies().then(() => {})
    }, [])

    const getCompanyByCode = (company_code, tmpCompanies) => {
        const filterCompanies = (tmpCompanies || companies).filter(c => c.company_code === company_code)
        return filterCompanies.length > 0 ? filterCompanies[0] : {}
    }

    const makeCompanyInfo = company => {
        let prefecture = getClassificationItemName(ClassificationTypeEnum.PREFECTURE.value, company.prefecture_code)
        return `〒${company.post_code}${prefecture}${company.address1}${company.address2 || ''}\nTEL：${
            company.phone_number
            // eslint-disable-next-line no-irregular-whitespace
        }　　FAX：${company.fax_number ? company.fax_number : ''}`
    }

    const handlerSenderIdChange = senderId => {
        if (senderId === SENDER_CODE.ESTIMATION_OTHER) {
            if (form.getFieldValue('customer_id') === project?.customer_id) {
                form.setFieldsValue({
                    sender_name: project?.customer?.estimation_sender_name || '',
                    sender_address: project?.customer?.estimation_sender_address || '',
                })
            } else
                form.setFieldsValue({
                    sender_name: customer?.estimation_sender_name || '',
                    sender_address: customer?.estimation_sender_address || '',
                })
        } else {
            const company = getCompanyByCode(senderId)
            form.setFieldsValue({
                sender_name: company.company_name,
                sender_address: company.post_code ? makeCompanyInfo(company) : company.address1,
            })
        }
    }

    const calculateSum = details => {
        const result = calculateTotal(details)
        form.setFieldsValue(result)
    }

    const checkOutOfStock = async (details, values) => {
        const products = calculateInStock(details)
        let isOutOfStock = false
        let data = null
        if (products.length > 0) {
            data = await api.post(APP_URLS.STOCKS_CHECK_OUT_OF_STOCK, products)
            isOutOfStock = data.isOutOfStock && columnMode === 2
            if (data) {
                setOosProducts({
                    isOutOfStock: isOutOfStock,
                    data: data.oosProducts,
                })
            }
        }
        if (!data || !isOutOfStock) processOnSubmit(values)
    }

    useEffect(() => {
        calculateSum(details)
    }, [details])

    useEffect(() => {
        if (isEdit && updatedDetail) {
            setIsValueChange(true)
        }
    }, [updatedDetail])

    const processOnSubmit = (values, redirectTo, credit = true, save = true) => {
        values.detail = []
        if (isValueChange && isCreateProject) {
            setIsValueChange(false)
        }
        if (
            !((customer?.bill_customer?.credit_amount ?? 0) === 0) &&
            customer?.bill_customer?.credit_amount < values.total &&
            credit
        ) {
            setConfirmCredit(true)
        } else {
            detailsOrder.map(detailOrder => values.detail.push(details[detailOrder]))
            // let filterDetails = values.detail.filter(detail => !isHeadline(detail) && !detail.product_name)
            let filterDetails = values.detail.filter(detail => !isHeadline(detail))
            if (filterDetails.length < 1 || filterDetails.filter(detail => !detail.product_name).length > 0) {
                showWarning('', '品名を入力してください。')
                return
            }
            if (save) {
                setConfirmSave(true)
            } else {
                if (projectForm) {
                    const projectValues = {
                        status: values.status,
                        project_name: values.project_name,
                        project_code: project.project_code,
                        customer_id: values.customer_id,
                        is_purchase_order: values.is_purchase_order,
                        pic_id: values.pic_id,
                        note: values.note,
                        estimation: {
                            ...values,
                            ...{
                                note: values.estimation_note,
                                pic_id: values.estimation_pic_id,
                                customer_id: values.estimation_customer_id,
                            },
                        },
                    }
                    onSubmit(projectValues, redirectTo)
                } else {
                    onSubmit(values, redirectTo)
                }
                setDefaultState()
                return true
            }
        }
    }

    // for preview button
    const previewData = () => {
        return [...detailsOrder.map(detailOrder => details[detailOrder])]
    }

    const suggestionList = customer.customer_pic ? customer.customer_pic.split(',') : []
    const [result, setResult] = useState(suggestionList)
    const handleSuggestion = value => {
        setResult(suggestionList.filter(r => r.toLowerCase().indexOf(value.toLowerCase()) > -1))
    }

    const isCreateProject = location.pathname.includes(Routes.private.business.PROJECT_CREATE.path)
    const canEditEstimation = can('ESTIMATION_EDITABLE') && !project.is_locked

    return (
        <>
            {canEditEstimation ? (
                <>
                    <RouterPrompt
                        ref={ref => (routerPromptRef.current = ref)}
                        handleSave={path => {
                            let values = form.getFieldsValue()
                            return processOnSubmit(values, path, true, false)
                        }}
                        isValueChange={isValueChange}
                        setDefaultState={() => setDefaultState()}
                    />
                    {confirmCredit && (
                        <InsModal
                            title="注意"
                            visible={confirmCredit}
                            onCancel={() => setConfirmCredit(false)}
                            onOk={() => processOnSubmit(form.getFieldsValue(), null, false)}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <div>
                                <p>合計額が与信限度額を超過しています。よろしいですか？</p>
                                <span>
                                    設定されている与信限度額：
                                    {formatNumber(customer?.bill_customer?.credit_amount ?? 0)}円
                                </span>
                            </div>
                        </InsModal>
                    )}
                    {confirmSave && (
                        <InsModal
                            title="確認"
                            visible={confirmSave}
                            onCancel={() => setConfirmSave(false)}
                            onOk={() => processOnSubmit(form.getFieldsValue(), null, false, false)}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <p>保存しますか？</p>
                        </InsModal>
                    )}
                    <InsModal
                        title={t('navigation:Stock check')}
                        width={760}
                        okText={t('はい')}
                        cancelText={t('いいえ')}
                        visible={oosProducts.isOutOfStock}
                        onCancel={() =>
                            setOosProducts({
                                isOutOfStock: false,
                                data: oosProducts.data,
                            })
                        }
                        onOk={() => {
                            setOosProducts({
                                isOutOfStock: false,
                                data: oosProducts.data,
                            })
                            processOnSubmit(form.getFieldsValue())
                        }}
                    >
                        <StockListInsufficient data={oosProducts.data} />
                    </InsModal>
                    <div className={'form-estimation bl-form'}>
                        <Form
                            className="ins-form pt-0"
                            form={form}
                            initialValues={initialValues}
                            onFinish={values => {
                                if (form.getFieldValue('estimation_classification') === 2)
                                    checkOutOfStock(details, values)
                                else processOnSubmit(values)
                            }}
                            onValuesChange={(changedValues, allValues) => {
                                if (isEdit && !isValueChange) {
                                    setIsValueChange(true)
                                }
                            }}
                        >
                            <Row>
                                <Col>
                                    <h1 className="page-title-big mt-24 mb-16">見積書作成</h1>
                                </Col>
                            </Row>
                            <Form.Item name="project_id" style={{ display: 'none' }}>
                                <InsInput />
                            </Form.Item>
                            <Row className="mb-16">
                                <Col span={12}>
                                    <Form.Item name="estimation_classification" label="区分">
                                        <InsSelect
                                            style={{ width: 180 }}
                                            onChange={value => {
                                                form.setFieldsValue({
                                                    estimation_code:
                                                        project_code +
                                                        (value === 1 ? '-MK-' : '-MS-') +
                                                        (project.count_estimation || 0),
                                                })
                                                setColumnMode(value)
                                            }}
                                        >
                                            <Select.Option value={1}>{'加工'}</Select.Option>
                                            <Select.Option value={2}>{'資材'}</Select.Option>
                                        </InsSelect>
                                    </Form.Item>
                                    <Form.Item label={t('column:Customer name')}>
                                        <Form.Item
                                            name={projectForm ? 'estimation_customer_id' : 'customer_id'}
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter customer name'),
                                                },
                                            ]}
                                        >
                                            <CustomerSelect
                                                placeholder={t('Customer search')}
                                                record={
                                                    projectForm
                                                        ? initialValues.estimation_customer
                                                        : initialValues.customer
                                                }
                                                style={{ width: 180 }}
                                                onChange={(id, customer) => {
                                                    handlerCustomerChange(id, customer)
                                                }}
                                                // showListLink={false}
                                                showCreateButton={false}
                                                showDetailLink={false}
                                            />
                                        </Form.Item>
                                        <Form.Item name="customer_pic_title" noStyle>
                                            <InsSelect style={{ width: 90, marginLeft: 4, verticalAlign: 'top' }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.CUSTOMER_TITLE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item label={t('column:Customer pic name')}>
                                        <Form.Item name="customer_pic_name" noStyle>
                                            <InsAutoComplete
                                                options={result}
                                                onSearch={handleSuggestion}
                                                placeholder="担当者検索"
                                                className="inline"
                                                style={{ width: 180 }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="customer_company_title" noStyle>
                                            <InsSelect style={{ width: 90, marginLeft: 4, verticalAlign: 'top' }}>
                                                {getClassificationsByType(
                                                    ClassificationTypeEnum.CUSTOMER_TITLE.value
                                                ).map(clf => (
                                                    <Select.Option key={clf.value} value={clf.value}>
                                                        {clf.text}
                                                    </Select.Option>
                                                ))}
                                            </InsSelect>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item name="estimation_name" label={t('column:Estimation name')}>
                                        <InsInput
                                            style={{ width: 340 }}
                                            placeholder="件名入力"
                                            showMaxLength
                                            maxLength={40}
                                        />
                                    </Form.Item>
                                    <Form.Item label={t('column:Delivery date')}>
                                        <Form.Item name="delivery_date_text" noStyle>
                                            <InsInput
                                                style={{ width: 180 }}
                                                placeholder="納期入力"
                                                onChange={value => {
                                                    const mm = moment.utc(value, dateFormat, true)
                                                    form.setFieldsValue({
                                                        delivery_date: mm.isValid() ? mm.format('YYYY-MM-DD') : '',
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                        <InsCustomDatePicker
                                            format={dateFormat}
                                            style={{ marginLeft: 4, verticalAlign: 'top' }}
                                            onChange={(d, s) => {
                                                form.setFieldsValue({
                                                    delivery_date: d.format('YYYY-MM-DD'),
                                                    delivery_date_text: s,
                                                })
                                            }}
                                        >
                                            <InsButton buttonStyle="default" icon={<CalendarTodayIcon size={16} />}>
                                                日付入力
                                            </InsButton>
                                        </InsCustomDatePicker>
                                        <Form.Item name="delivery_date" style={{ display: 'none' }}>
                                            <InsInput />
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item label={t('column:Expiration date')}>
                                        <Form.Item name="expiration_date_text" noStyle>
                                            <InsInput
                                                style={{ width: 180 }}
                                                placeholder="有効期限入力"
                                                onChange={value => {
                                                    const mm = moment.utc(value, dateFormat, true)
                                                    form.setFieldsValue({
                                                        expiration_date: mm.isValid() ? mm.format('YYYY-MM-DD') : '',
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                        <InsCustomDatePicker
                                            format={dateFormat}
                                            style={{ marginLeft: 4, verticalAlign: 'top' }}
                                            onChange={(d, s) => {
                                                form.setFieldsValue({
                                                    expiration_date: d.format('YYYY-MM-DD'),
                                                    expiration_date_text: s,
                                                })
                                            }}
                                        >
                                            <InsButton buttonStyle="default" icon={<CalendarTodayIcon size={16} />}>
                                                日付入力
                                            </InsButton>
                                        </InsCustomDatePicker>
                                        <Form.Item name="expiration_date" style={{ display: 'none' }}>
                                            <InsInput />
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item name="payment_condition" label={t('column:Payment condition')}>
                                        <InsInput style={{ width: 180 }} placeholder="支払条件入力" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="estimation_code"
                                        label={t('column:Estimation code')}
                                        rules={[
                                            {
                                                // validateTrigger: 'onBlur',
                                                required: true,
                                                message: t('validation:Please enter estimation code'),
                                                // check unique
                                            },
                                            {
                                                // validateTrigger: 'onBlur',
                                                validator: async (rule, value) => {
                                                    if (isEdit && initialValues.estimation_code.trim() === value.trim())
                                                        return Promise.resolve()

                                                    if (value.trim()) {
                                                        const data = await api.post(
                                                            APP_URLS.ESTIMATIONS_CHECK_EXISTS_CODE,
                                                            {
                                                                estimation_code: value,
                                                            }
                                                        )
                                                        if (data && data.is_existed) {
                                                            return Promise.reject(
                                                                t('validation:Estimation code existed')
                                                            )
                                                        }
                                                    }

                                                    return Promise.resolve()
                                                },
                                            },
                                            {
                                                max: 20,
                                                message: t('validation:{{item}} is invalid', {
                                                    item: t('column:Estimation code'),
                                                }),
                                            },
                                        ]}
                                    >
                                        <InsInput style={{ width: 180 }} maxLength={20} />
                                    </Form.Item>
                                    <Form.Item
                                        name="issued_date"
                                        label={t('column:Issued date')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter issued date'),
                                            },
                                        ]}
                                    >
                                        <InsDatePicker
                                            format={dateFormat}
                                            placeholder="発行日入力"
                                            style={{ width: 180 }}
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="sender_id"
                                        label="差出名"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter sender name'),
                                            },
                                        ]}
                                    >
                                        <InsSelect
                                            style={{ width: 180 }}
                                            onChange={value => handlerSenderIdChange(value)}
                                        >
                                            {getClassificationsByType(
                                                ClassificationTypeEnum.ESTIMATION_SENDER.value
                                            ).map(clf => (
                                                <Select.Option key={clf.value} value={clf.value}>
                                                    {clf.text}
                                                </Select.Option>
                                            ))}
                                        </InsSelect>
                                    </Form.Item>
                                    <Form.Item name="sender_name" label=" ">
                                        <InsInput
                                            defaultValue={initialValues.sender_name}
                                            style={{ width: 180 }}
                                            value={'株式会社TM'}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item name="sender_address" label=" " style={{ width: 420, marginBottom: 8 }}>
                                        <InsTextArea defaultValue={initialValues.sender_address} />
                                    </Form.Item>
                                    <Form.Item
                                        name={projectForm ? 'estimation_pic_id' : 'pic_id'}
                                        label={t('column:Pic name')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('validation:Please enter pic name'),
                                            },
                                        ]}
                                    >
                                        <UserSelect
                                            record={projectForm ? initialValues.estimation_pic : initialValues.pic}
                                            style={{ width: 180 }}
                                            placeholder="TM担当者検索"
                                            dropdownMatchSelectWidth={false}
                                            showListLink={true}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <EstimationDragAndDrop />

                            <Row className="estimation-detail-control" justify="space-between">
                                <EstimationDetailAddButton />
                                <Row className="total">
                                    <Form.Item name="gross_profit" label="粗利">
                                        <InsInputNumber disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="subtotal" label="小計">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="consumption_tax" label="消費税">
                                        <InsInputNumber min={0} disabled style={{ width: 104 }} />
                                    </Form.Item>
                                    <Form.Item name="total" label="合計	：:">
                                        <InsInputNumber min={0} disabled style={{ width: 120 }} />
                                    </Form.Item>
                                </Row>
                            </Row>
                            <h3>備考</h3>
                            <Form.Item name={projectForm ? 'estimation_note' : 'note'}>
                                <InsTextArea allowClear={false} />
                            </Form.Item>
                            <InsFloatBar visible={true} className="with-sidebar">
                                <Row gutter={8}>
                                    <Col>
                                        <EstimationPreviewModal
                                            project={project}
                                            estimation={Object.assign({}, initialValues, form.getFieldsValue(), {
                                                detail: previewData(),
                                                customer: customer || initialValues['estimation_customer'],
                                                note: form.getFieldValue('note'),
                                                pic: initialValues['pic'],
                                                customer_id: customer?.id || initialValues['customer_id'],
                                                pic_id: form.getFieldValue('pic_id'),
                                            })}
                                            visible={modalPreviewVisible}
                                            onCancel={() => {
                                                setModalPreviewVisible(false)
                                            }}
                                        >
                                            <InsButton
                                                buttonStyle="default"
                                                buttonSize="medium"
                                                centered
                                                icon={<PreviewIcon size={16} className="color-pantone-2738-c" />}
                                                onClick={() => {
                                                    setModalPreviewVisible(true)
                                                }}
                                            >
                                                {t('common:Preview')}
                                            </InsButton>
                                        </EstimationPreviewModal>
                                    </Col>
                                </Row>
                                <Row gutter={8} justify="end">
                                    <Col>
                                        <InsButton
                                            buttonStyle="outline"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            onClick={() => {
                                                if (isCreateProject) {
                                                    history.push(`${Routes.private.business.PROJECT.path}`)
                                                } else {
                                                    history.goBack()
                                                }
                                            }}
                                        >
                                            {t('common:Cancel')}
                                        </InsButton>
                                    </Col>
                                    <Col>
                                        <InsButton
                                            buttonStyle="primary"
                                            buttonSize="big"
                                            centered
                                            width="140px"
                                            isSubmitButton={true}
                                        >
                                            {isEdit ? t('common:Save') : t('common:Save')}
                                        </InsButton>
                                    </Col>
                                </Row>
                            </InsFloatBar>
                        </Form>
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    )
}

const mapState = state => ({
    currentUser: state.auth.user,
    details: state.estimationDetail.details,
    detailsOrder: state.estimationDetail.detailsOrder,
    updatedDetail: state.estimationDetail.updatedDetail,
    columnMode: state.estimationDetail.columnMode,
})

const mapDispatch = {
    setDefaultState,
    parseData,
    setColumnMode,
}

export default connect(mapState, mapDispatch)(withRouter(EstimationForm))

EstimationForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    currentUser: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
    detailsOrder: PropTypes.object.isRequired,
    setDefaultState: PropTypes.func.isRequired,
    parseData: PropTypes.func.isRequired,
    setColumnMode: PropTypes.func.isRequired,
    columnMode: PropTypes.number,
    projectForm: PropTypes.any,
    projectCreateDoc: PropTypes.bool,
}
